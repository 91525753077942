import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
const inititalState = {
    auth: {
      authenticated: {
        token: localStorage.getItem("token"),
        isAuth: localStorage.getItem("isAuth"),
        account: localStorage.getItem("account"),
        userType: localStorage.getItem("userType"),
        valid: localStorage.getItem("valid"),
        user: localStorage.getItem("user")
      }
    }
  };
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    rootReducer,
    inititalState,
    composeEnhancers(applyMiddleware(thunk))
  );
  export default store;