import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import store from "./redux/store";
import './index.css';
import App from './App';
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const history = createBrowserHistory({ basename: baseUrl });



ReactDOM.render(

  <Provider store={store} >
    <Router  history={history}>
      <ConfigProvider locale={enUS}>
        <App />
      </ConfigProvider>
    </Router>
  </Provider>,
 
  document.getElementById('root')
);

